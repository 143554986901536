<template>
  <div>
    <v-card class="pa-0" flat>
      <v-card-text class="pt-3 lightGrey rounded-0" style="position: fixed; z-index: 100; min-width: 100%; top: 47px">
        <v-row class="text-right" justify="end" no-gutters>
          <v-btn v-if="$helpers.hasPermission(['delete_member'])" @click="dialogDelete = true" outlined>Desactivar miembro</v-btn>
          <v-menu v-if="$helpers.hasPermission(['view_group', 'change_group']) || $helpers.hasPermission(['change_member'])"  close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="145">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
                <v-icon left size="18">mdi-pencil</v-icon>
                Editar
                <v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0 bgSearch">
              <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_group', 'change_group'])" @click="goTo(0)" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2">Grupos</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" v-if="$helpers.hasPermission(['change_member'])" @click="goTo(1)" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2">Permisos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-col cols="12" class="pa-0">
          <v-toolbar color="transparent" flat dense>
            <v-toolbar-title class="subtitle-1 font-weight-bold secondary--text"><v-icon left size="20" color="secondary">mdi-account</v-icon>Miembro
            </v-toolbar-title>
          </v-toolbar>
          <!-- basic inf-->
          <v-row class="ma-0" no-gutters>
            <v-col cols="5" class="pa-0 pl-1 text-left">
              <span :class="{ 'd-inline-block text-truncate' : !loading }" style="max-width: 190px;">
                <span class="font-weight-bold secondary--text">Nombre: </span>
                <v-avatar v-if="loading" tile class="placeholder rounded-pill" height="15" width="130" />
                <template v-else>
                  <span v-if="!['', ' '].includes(editable.name)" class="fontDraw--text">
                    <span class="text-capitalize"> {{editable.name | lower}}</span>
                  </span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </template>
              </span>
            </v-col>
            <v-col class="px-1 py-0">
              <span :class="{ 'd-inline-block text-truncate' : !loading }" style="max-width: 300px;">
                <span class="font-weight-bold secondary--text">Email: </span>
                <v-avatar v-if="loading" tile class="placeholder rounded-pill" height="15" width="240" />
                <template v-else>
                  <span v-if="editable.email" class="fontDraw--text">{{editable.email}}</span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </template>
              </span>
            </v-col>
          </v-row>
          <!-- end basic inf -->
        </v-col>
      </v-card-text>
      <v-divider class="mt-2" />
      <v-card-text class="py-0 px-0 overflow-y-auto" style="margin-top: 170px">
        <skeleton-groups v-if="loading" type="panel" />
        <template v-else>
          <v-divider />
          <v-col class="px-5">
            <v-toolbar color="transparent" flat dense>
              <v-toolbar-title class="subtitle-1 font-weight-bold secondary--text d-block mb-n4"><v-icon left size="20" color="secondary">mdi-cog</v-icon>Permisos</v-toolbar-title>
            </v-toolbar>
            <v-col class="py-2 px-1" v-if="!!noData">
              <span class="d-block body-1 fontDraw--text">No existen permisos</span>
            </v-col>
            <template v-else>
              <div v-for="(item, i) in list" :key="i">
                <span class="my-3 d-block font-weight-bold fontBody--text" v-if="((item.children || []).filter((item) => item.active) || []).length">{{item.category}}</span>
                <v-row class="ma-0">
                  <v-col cols="6" class="pa-0" v-for="(c, index) in (item.children || []).filter((item) => item.active)" :key="index">
                    <span class="d-inline-block text-truncate" style="max-width: 230px;"><v-icon class="mr-1" x-small color="primary">mdi-circle-medium</v-icon>{{c.name}}</span>
                    <!-- <div class="ml-6" v-if="c.name.search(/documentos/) !== -1 && item.category === 'Documentos'">
                      <span class="d-block" v-for="(doc, i) in currentDocumentListEnabled" :key="i">
                        <span class="pa-0 body-1 d-inline-block text-truncate" style="max-width: 90%;" v-if="doc.permissions.includes(c.id)">{{doc.name}}</span>
                      </span>
                    </div> -->
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-col>
        </template>
        <template v-if="$helpers.hasPermission(['view_member'])">
          <v-divider class="mt-4" />
          <skeleton-groups class="mt-4" v-if="loadingDetail && loading" type="panel" />
          <v-col v-else class="px-5">
            <v-toolbar color="transparent" flat dense>
              <v-toolbar-title class="subtitle-1 font-weight-bold secondary--text"><v-icon left size="20" color="secondary">mdi-account-group</v-icon>Grupos</v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters v-if="currentListGroupsActive.length">
              <v-col cols="6" class="pa-0" v-for="(member, index) in currentListGroupsActive" :key="index">
                <span class="d-inline-block text-truncate" style="max-width: 230px;">
                  <v-icon class="mr-1" x-small color="primary">mdi-circle-medium</v-icon>
                  <span v-if="['', ' '].includes(member.name)">{{member.email}}</span>
                  <span class="text-capitalize" v-else> {{member.name | lower}}</span>
                </span>
              </v-col>
            </v-row>
            <v-col class="pb-5 pl-1" v-else>
              <span class="d-block body-1 fontDraw--text">
                {{ $helpers.hasPermission(['view_groups']) ? 'No existen grupos' : 'No tienes permisos para visualizar esta sección' }}
              </span>
            </v-col>
          </v-col>
        </template>
      </v-card-text>
    </v-card>
    <!-- dialog deleteMember -->
    <v-dialog v-model="dialogDelete" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">{{`${editable.is_active ? 'Desactivar' : 'Activar'} miembro`}}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">¿Qué ocurre cuando se {{editable.is_active ? 'desactiva' : 'activa'}} un miembro?</span>
          <span class="d-block mt-4">1. El miembro {{editable.is_active ? 'ya no' : ''}} podrá trabajar en el espacio de trabajo.</span>
          <span class="d-block mt-2 mb-3">2. Toda la información creada por el miembro seguirá estando disponible.</span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="deleteMember">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog deleteMember -->
  </div>
</template>
<script>
import permissionList from '@/collections/permissionsList'
import { groupBy } from 'lodash'
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import SkeletonGroups from '@/modules/account/views/groups/components/SkeletonGroups'

  export default {
    props: {
      id: {
        type: String,
        default: ''
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    components: {
      SkeletonGroups
    },
    mixins: [
    GenericViewMixin
    ],
    data: () => ({
      noData: false,
      name: '',
      description: '',
      confirm: '',
      dialogDelete: false,
      permissionList,
      editable: {},
      list: [],
      loading: false,
      loadingDetail: false,
      permissionsList: [],
      currentGroupsList: [],
      documentTypes: DocumentTypes,
      currentDocumentListEnabled: []
    }),
    computed: {
      ...mapState({
        membersList: state => state.accounts.membersList,
        groupsList: state => state.accounts.groupsList
      }),
      currentListGroupsActive () {
        return this.currentGroupsList?.filter((item) => item.active) ?? []
      },
      currentParams () {
        let params = JSON.parse(JSON.stringify(this.$route.params))
        delete params.id
        return params
      }
    },
    watch: {
      active (val) {
        if (val) {
          this.getData()
        }
      }
    },
    mounted () {
      this.loadingDetail = true
      if (this.$helpers.hasPermission(['view_group'])) {
        this.$store.dispatch('accounts/LIST', {
          resource: 'groups',
          query: {
            ...this.$route.query,
            page_size: 100
          }
        })
        .finally(() => {
          this.loadingDetail = false
        })
      }
    },
    methods: {
      getData () {
        this.permissionsList = JSON.parse(JSON.stringify(permissionList))
        this.loading = true
        this.$store.dispatch('accounts/LIST', {
          resource: `members/${this.id}`,
          query: {
            ...this.$route.query
          }
        })
        .then((response) => {
          const result = response.data
          this.editable = {
            id: result.id,
            is_owner: result.is_owner,
            is_active: result.is_active,
            name: `${result.first_name} ${result.last_name}`,
            email: result.email,
            permissions: this.groupsList?.filter(({id}) => result.groups.includes(id)) ?? []
          }
          /** set groups list */
          this.currentGroupsList = [...this.groupsList]
          this.currentGroupsList.forEach((item) => {
            item.active = result.groups.includes(item.id)
          })

          /** end set groups list */

          /** set permission list */
          this.list = [...this.permissionsList]
          this.list.forEach((item) => {
            item.active = result.permissions.includes(item.id)
          })
          this.list = [...this.groupBy(this.list)]
          /** end set permission list */

          /** set documentType */
          let documentTypePermissions = Object.entries(groupBy(result.document_type_permissions, 'document_type')).map(([key, value]) => {
            return {
              id: key,
              permissions: value.map(({permission}) => permission)
            }
          })

          this.currentDocumentListEnabled = this.documentTypes?.filter((d) => {
            const founded = documentTypePermissions.find((item) => item.id === d.id)
            if (founded !== undefined) {
              d.permissions = founded.permissions
              return true
            }
            return false
          })
          if (this.currentDocumentListEnabled.length) {
            this.list.forEach((item) => {
              if (item.category === 'Documentos') {
                item.children.forEach((elm) => {
                  if (['add_document', 'view_document'].includes(elm.id)) {
                    elm.active = this.currentDocumentListEnabled.some(({permissions}) => permissions.includes(elm.id))
                  }
                })
              }
            })
          }
          let activeList = []
          this.list.forEach((item) => {
            activeList = [...activeList, ...item.children?.map((c) => !c.active)]
          })
          this.noData = activeList.every((item) => item)
          /** end set documentType */
        })
        .finally(() => {
          this.loading = false
        })
      },
      groupBy (list = []) {
        return Object.entries(groupBy(list, 'category')).map(([key, value]) => {
          return {
            category: key,
            children: value
          }
        })
      },
      goTo (step = 0) {
        this.$emit('close')
        setTimeout(() => {
          this.$router.push({ name: 'AccountMemberUpdate', params: { id: this.id, ...this.currentParams }, query: {step: step} })
        }, 100)
      },
      deleteMember () {
        this.$store.dispatch('accounts/UPDATE', {
          resource: 'members',
          payload: {
            is_active: !this.editable.is_active
          },
          id: this.editable.id
        })
        .then(() => {
          this.$emit('updateList')
          this.$dialog.message.info(`El miembro ha sido ${!this.editable.is_active ? 'desactivado' : 'activado'} en la organización`)
        })
        .catch((error) => {
          if (error.toString().search('400') !== -1) {
            this.dialogDelete = false
          }
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.dialogDelete = false
        })
      }
    }
  }
  </script>