<template>
  <div>
    <template v-if="['AccountGroupsRetrieve', 'AccountMembersRetrieve'].includes($route.name)">
      <div class="d-flex mb-5">
        <v-spacer />
        <v-skeleton-loader transition="fade-transition" type="button" />
        <v-skeleton-loader class="ml-2" transition="fade-transition" type="button" />
      </div>
      <v-card class="bgPanel pt-6 rounded" flat>
        <v-col cols="2" class="px-5">
          <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
        </v-col>
        <div :class="['d-flex mt-2 px-5', {'pb-3' : $route.name === 'AccountMembersRetrieve'}]">
          <v-row class="mb-1">
            <v-col cols="3" class="px-0 pl-3">
              <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
            </v-col>
            <v-col :cols="$route.name === 'AccountMembersRetrieve' ? 5 : 6" :class="`ml-12 pl-10, ${$route.name !== 'AccountMembersRetrieve' ? 'mt-n5' : ''}`">
              <v-skeleton-loader class="skeleton-group" transition="fade-transition" :type="$route.name !== 'AccountMembersRetrieve' ? 'list-item-two-line' : 'text'" />
            </v-col>
          </v-row>
        </div>
        <v-divider />
        <div class="d-flex px-5">
          <v-row class="ma-0" no-gutters>
            <v-col cols="7" class="pb-4 pt-6" :order="$route.name === 'AccountMembersRetrieve' ? 2 : 1">
              <v-col cols="5" class="px-0">
                <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
              </v-col>
              <v-row class="">
                <v-col class="pl-0 pr-10">
                  <v-col cols="8" class="pb-0 pt-2">
                    <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
                  </v-col>
                  <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="list-item-three-line" />
                </v-col>
                <v-col class="px-0">
                  <v-col cols="8" class="pb-0 pt-2">
                    <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
                  </v-col>
                  <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="list-item-three-line" />
                </v-col>
              </v-row>
            </v-col>
            <v-col :class="['pb-4 pt-6', $route.name === 'AccountMembersRetrieve' ? 'pr-10 mr-10' : 'pl-10 ml-10']" :order="$route.name === 'AccountMembersRetrieve' ? 1 : 2" :style="`border-${$route.name !== 'AccountMembersRetrieve' ? 'left' : 'right'}: 1px solid var(--light-blue-grey) !important`">
              <v-col cols="8" class="px-0">
                <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
              </v-col>
              <v-row class="">
                <v-col class="px-0">
                  <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="list-item-three-line" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
    <template v-if="type === 'inf'">
      <div class="d-flex mt-2">
        <div class="align-stretch">
          <v-skeleton-loader transition="fade-transition" type="text" width="160" />
        </div>
        <v-row class="ml-16 px-8">
          <v-col class="pl-4">
            <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="image" :height="40" />
          </v-col>
          <v-col class="ml-4">
            <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="image" :height="40" />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex mt-3">
        <div class="align-stretch">
          <v-skeleton-loader transition="fade-transition" type="text" width="160" />
        </div>
        <v-row class="ml-16 px-8">
          <v-col class="pl-4">
            <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="image" :height="100" />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-if="type === 'permissions'">
      <v-col class="pa-0">
        <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="60" />
        <v-divider/>
        <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="60" />
        <v-divider/>
        <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="60" />
      </v-col>
    </template>
    <template v-if="type === 'members'">
      <v-row v-for="elm in 4" :key="elm">
        <v-col v-for="item in 2" :key="item">
          <v-row class="" aling="center">
            <v-col cols="7">
              <v-skeleton-loader transition="fade-transition" type="text" />
            </v-col>
            <v-spacer />
            <v-col class="pl-16 mt-n1">
              <v-skeleton-loader  transition="fade-transition" type="image" :width="50" :height="25" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <div class="px-1" v-if="type === 'panel'">
      <v-col cols="7" class="pl-3 pb-0">
        <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="text" />
      </v-col>
      <v-row class="mb-1" no-gutters>
        <v-col cols="6" class="" v-for="item in 2" :key="item">
          <v-skeleton-loader class="skeleton-group" transition="fade-transition" type="list-item-two-line" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  })
}
</script>