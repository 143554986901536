export default [
  // {
  //   name: 'Eliminar log',
  //   id: 'delete_logentry'
  // },
  // {
  //   name: 'Ver log',
  //   id: 'view_logentry'
  // },
  {
    name: 'Cargar certificado digital',
    id: 'add_genericcertificate',
    category: 'SII',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Reemplazar certificado digital',
  //   id: 'change_certificate',
  //   category: 'sii'
  // },
  {
    name: 'Ver certificado digital',
    id: 'view_genericcertificate',
    category: 'SII',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Ver documentos cedidos',
  //   id: 'view_grant'
  // },
  {
    name: 'Cargar folios ',
    id: 'add_caf',
    category: 'Folios',
    active: false,
    countries: ['CL']
  },
  {
    name: 'Ver folios',
    id: 'view_caf',
    category: 'Folios',
    active: false,
    countries: ['CL']
  },
  // {
  //   name: 'Can add swap',
  //   id: 'add_swap'
  // },
  // {
  //   name: 'Can view swap',
  //   id: 'view_swap'
  // },
  {
    name: 'Responder documento de compra',
    id: 'add_swapresponse',
    category: 'Recepción',
    active: false,
    countries: ['CL']
  },
  {
    name: 'Ver respuesta documento de compra',
    id: 'view_swapresponse',
    category: 'Recepción',
    countries: ['CL'],
    active: false
  },
  // {
  //   name: 'Can add supplier response',
  //   id: 'add_supplierresponse'
  // },
  // {
  //   name: 'Can view supplier response',
  //   id: 'view_supplierresponse'
  // },
  // {
  //   name: 'Crear documentos',
  //   id: 'add_document',
  //   category: 'Documentos',
  //   active: false,
  //   countries: ['CL', 'PE']
  // },
  {
    name: 'Administrar documentos',
    id: 'view_document',
    category: 'Documentos',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Crear cesión',
  //   id: 'add_grant',
  //   category: 'Documentos',
  //   active: false,
  //   countries: ['CL', 'PE']
  // },
  {
    name: 'Ver documentos de compra',
    id: 'view_purchase',
    category: 'Recepción',
    active: false,
    countries: ['CL']
  },
  {
    name: 'Crear proceso de facturación por lote',
    id: 'add_batch',
    category: 'Facturación por lotes',
    countries: ['CL']
  },
  // {
  //   name: 'Editar proceso de facturación por lote',
  //   id: 'change_batch',
  //   category: 'Facturación por lotes'
  // },
  {
    name: 'Eliminar proceso de facturación por lote',
    id: 'delete_batch',
    category: 'Facturación por lotes',
    countries: ['CL']
  },
  {
    name: 'Ver proceso de facturación por lote',
    id: 'view_batch',
    category: 'Facturación por lotes',
    countries: ['CL']
  },
  {
    name: 'Ver invitaciones de miembros',
    id: 'view_invitation',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Enviar invitaciones de miembros',
    id: 'add_invitation',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Editar invitaciones de miembros',
  //   id: 'change_invitation'
  // },
  {
    name: 'Eliminar invitaciones de miembros',
    id: 'delete_invitation',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Crear miembro',
  //   id: 'add_member'
  // },
  {
    name: 'Editar miembro',
    id: 'change_member',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver miembro',
    id: 'view_member',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar miembro',
    id: 'delete_member',
    category: 'Miembros',
    active: false,
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Editar cuenta',
  //   id: 'change_account'
  // },
  {
    name: 'Crear grupos de usuarios',
    id: 'add_group',
    category: 'Grupos',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar grupo de usuarios',
    id: 'delete_group',
    category: 'Grupos',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver grupos de usuarios',
    id: 'view_group',
    category: 'Grupos',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar grupos de usuarios',
    id: 'change_group',
    category: 'Grupos',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear notificaciones',
    id: 'add_notification',
    category: 'Notificaciones',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver notificaciones',
    id: 'view_notification',
    category: 'Notificaciones',
    active: false,
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar notificaciones',
    id: 'change_notification',
    category: 'Notificaciones',
    active: false,
    countries: ['CL', 'PE']
  }
  // {
  //   name: 'Ver contribuyente',
  //   id: 'view_taxpayer'
  // },
  // {
  //   name: 'Can add bank',
  //   id: 'add_bank'
  // },
  // {
  //   name: 'Can change bank',
  //   id: 'change_bank'
  // },
  // {
  //   name: 'Can delete bank',
  //   id: 'delete_bank'
  // },
  // {
  //   name: 'Can view bank',
  //   id: 'view_bank'
  // },
  // {
  //   name: 'Can add customer',
  //   id: 'add_customer'
  // },
  // {
  //   name: 'Can change customer',
  //   id: 'change_customer'
  // },
  // {
  //   name: 'Can delete customer',
  //   id: 'delete_customer'
  // },
  // {
  //   name: 'Can view customer',
  //   id: 'view_customer'
  // },
  // {
  //   name: 'Editar tienda',
  //   id: 'change_branch'
  // },
  // {
  //   name: 'Ver tienda',
  //   id: 'view_branch'
  // },
  // {
  //   name: 'Editar  POS',
  //   id: 'change_pos'
  // },
  // {
  //   name: 'Ver POS',
  //   id: 'view_pos'
  // },
  // {
  //   name: 'Can add message',
  //   id: 'add_message'
  // },
  // {
  //   name: 'Can view message',
  //   id: 'view_message'
  // },
  // {
  //   name: 'Crear un proveedor',
  //   id: 'add_supplier'
  // },
  // {
  //   name: 'Editar un proveedor',
  //   id: 'change_supplier'
  // },
  // {
  //   name: 'Eliminar un proveedor',
  //   id: 'delete_supplier'
  // },
  // {
  //   name: 'Ver proveedores',
  //   id: 'view_supplier'
  // },
  // {
  //   name: 'Ver eventos',
  //   id: 'view_event'
  // },
  // {
  //   name: 'Can add webhook',
  //   id: 'add_webhook'
  // },
  // {
  //   name: 'Can change webhook',
  //   id: 'change_webhook'
  // },
  // {
  //   name: 'Can delete webhook',
  //   id: 'delete_webhook'
  // },
  // {
  //   name: 'Can view webhook',
  //   id: 'view_webhook'
  // },
  // {
  //   name: 'Can view conversation',
  //   id: 'view_conversation'
  // },
  // {
  //   name: 'Editar configuración de la empresa',
  //   id: 'change_irsconnection'
  // },
  // {
  //   name: 'Ver configuración de la empresa',
  //   id: 'view_irsconnection'
  // },
  // {
  //   name: 'Crear plantillas PDF',
  //   id: 'add_pdftemplate'
  // },
  // {
  //   name: 'Editar plantillas PDF',
  //   id: 'change_pdftemplate'
  // },
  // {
  //   name: 'Eliminar plantillas PDF',
  //   id: 'delete_pdftemplate'
  // },
  // {
  //   name: 'Ver plantillas PDF',
  //   id: 'view_pdftemplate'
  // },
  // {
  //   name: 'Can change Setup key',
  //   id: 'change_setupkey'
  // },
  // {
  //   name: 'Can view Setup key',
  //   id: 'view_setupkey'
  // },
  // {
  //   name: 'Crear Certificado SUNAT',
  //   id: 'add_sunatcertificate'
  // },
  // {
  //   name: 'Reemplazar Certificado SUNAT',
  //   id: 'change_sunatcertificate'
  // },
  // {
  //   name: 'Ver Certificado SUNAT',
  //   id: 'view_sunatcertificate'
  // },
  // {
  //   name: 'Editar configuración del POS',
  //   id: 'change_posaccountsetting'
  // },
  // {
  //   name: 'Ver configuaración del POS',
  //   id: 'view_posaccountsetting'
  // },
  // {
  //   name: 'Ver eventos del POS',
  //   id: 'view_eventpos'
  // }
]